import * as React from 'react';
import PropTypes from 'prop-types';
// @mui
import { styled, alpha } from '@mui/material/styles';
import {
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  MenuItem,
  Select,
  Checkbox,
  ListItemText,
  TextField,
  FormControl,
  InputLabel
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// component
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 75,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const StyledRoot2 = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'stat',
  padding: '0px !important',
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 240,  
  height: 55,
  marginRight: 20,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: 320,
    boxShadow: theme.customShadows.z8,
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

const StyleLoaction = styled(Select)(({ theme }) => ({
  width: 240,
  height: 55,
  marginRight: 20,
  fontSize: '0.825rem',
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    boxShadow: theme.customShadows.z8,
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

const StyleDate = styled(DatePicker)(({ theme }) => ({
  width: 240,
  fontSize: '0.825rem',
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

const StyleMenuItem = styled(MenuItem)(({ theme }) => ({
  backgroundColor: 'transparent',
  padding: '6px 3px',
  margin: 3,
  borderRadius: '6px',
}));

const MenuProps = {
  PaperProps: {
    style: {
      width: 240,
      paddingLeft: 8,
      paddingRight: 8,
      outline: 0,
      backdropFilter: 'blur(20px)',
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
      color: 'rgb(33, 43, 54)',
    },
  },
};

const names = ['BRI HQ', 'BRI UAQ', 'PRS SAU', 'PRS SAJ'];

const options = [
    { value: '1', label: 'BRI HQ' },
    { value: '2', label: 'BRI UAQ' },
    { value: '3', label: 'PRS SAU' },
    { value: '4', label: 'PRS SAJ' },
  ];

// ----------------------------------------------------------------------

MaterialRequisitionListToleBar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
};

export default function MaterialRequisitionListToleBar({ numSelected, filterName, onFilterName }) {
  const [personName, setPersonName] = React.useState([]);
  const [selectedDate, setSelectedDate] = React.useState(null);
  const [selectedValues, setSelectedValues] = React.useState([]);

  const handleChange2 = (event) => {
    setSelectedValues(event.target.value);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    console.log(selectedDate);
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  return (
    <StyledRoot
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter',
        }),
      }}
    >
      <StyledRoot2>
        {numSelected > 0 ? (
          <Typography component="div" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <StyledSearch
            value={filterName}
            size='small'
            onChange={onFilterName}
            placeholder="Search"
            startAdornment={
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
              </InputAdornment>
            }
          />
        )}

        <div>
      <FormControl sx={{ mr:2.5, width: 300 }}>
        <InputLabel id="demo-multiple-checkbox-label">Location</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={personName}
          onChange={handleChange}
          input={<OutlinedInput label="Location" />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
        >
          {names.map((name) => (
            <StyleMenuItem key={name} value={name}>
            <Checkbox style={{ padding: '4px', marginRight: '6px' }} checked={personName.indexOf(name) > -1} />
            <ListItemText
              primary={
                <span className="custom-text" style={{ fontSize: '0.875rem' }}>
                  {name}
                </span>
              }
            />
          </StyleMenuItem>
          ))}
        </Select>
      </FormControl>
        </div>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <StyleDate
            label="Released Date"
            value={selectedDate}
            onChange={handleDateChange}
            renderInput={(params) => (
              <StyleDate
                style={{
                  padding: '4px', // Adjust the padding as needed
                  fontSize: '14px', // Adjust the font size as needed
                }}
                {...params}
              />
            )}
          />
        </LocalizationProvider>
      </StyledRoot2>
     
    </StyledRoot>
  );
}
