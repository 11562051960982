// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard',
    icon: icon('ic_analytics'),
    area: 'default'
  }
  ,
  {
    title: 'Employee Master',
    path: '/employeeMaster',
    icon: icon('ic_employee'),
    area: 'Setup'
  },
  {
    title: 'Machinery Master',
    path: '/machineryMaster',
    icon: icon('ic_machinery'),
    area: 'Setup'
  },
  {
    title: 'User Master',
    path: '/userMaster',
    icon: icon('ic_user_2'),
    area: 'Setup'
  },
  {
    title: 'User Roles Master',
    path: '/userRoleMaster',
    icon: icon('ic_user_role'),
    area: 'Setup'
  },
  {
    title: 'Email Alert Master',
    path: '/emailAlertMaster',
    icon: icon('ic_mail_alert'),
    area: 'Setup'
  },
  {
    title: 'Work Order List',
    path: '/workOrderList',
    icon: icon('ic_wo_list'),
    area: 'Transcations'
  },
  {
    title: 'WO Operation Planning',
    path: '/woOperationPlanning',
    icon: icon('ic_wo_op'),
    area: 'Transcations'
  },
  {
    title: 'Operation Activites',
    path: '/operationActivites',
    icon: icon('ic_oa'),
    area: 'Transcations'
  },
  {
    title: 'Material Requisition',
    path: '/materialRequisition',
    icon: icon('ic_mr'),
    area: 'Transcations'
  },
  {
    title: 'Time Sheets',
    path: '/timeSheets',
    icon: icon('ic_timesheet'),
    area: 'Transcations'
  },
  {
    title: 'WO Planning Report',
    path: '/wopReport',
    icon: icon('ic-report-1'),
    area: 'Reports'
  },
  {
    title: 'WOP EST M Report',
    path: '/wopEstModificationReport',
    icon: icon('ic-report-2'),
    area: 'Reports'
  },
  {
    title: 'MRF vs AMC Report',
    path: '/mrfVsAmcReport',
    icon: icon('ic-report-3'),
    area: 'Reports'
  },
  {
    title: 'Time Sheet Report',
    path: '/timeSheetReport',
    icon: icon('ic_sheettimerepo'),
    area: 'Reports'
  },
  {
    title: 'Labour Time Sheet Report',
    path: '/labourTimeSheetReport',
    icon: icon('ic_labourtime'),
    area: 'Reports'
  },
  {
    title: 'Machine Time Sheet Report',
    path: '/machineTimeSheetReport',
    icon: icon('ic_machinetime'),
    area: 'Reports'
  },
  {
    title: 'ELH vs ALH Report',
    path: '/elhVsAlhReport',
    icon: icon('ic_timesheet'),
    area: 'Reports'
  },
];

export default navConfig;
