import React, { useState, useCallback } from 'react';
import { Button, Dialog, DialogActions, DialogContent, ButtonGroup, DialogTitle } from '@mui/material';

const WoopDialogBox = ({ open, onClose, onConfirm1, onConfirm2, btnColor1, btnColor2, dContent, dTitle, btnText1, btnText2 }) => {
  return (
    <Dialog open={open}>
      <DialogTitle>{dTitle}</DialogTitle>
      <DialogContent>{dContent}</DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose} style={{ borderColor: 'black', color: 'black' }} size="small">
          Cancel
        </Button>
        <ButtonGroup size="small" aria-label="small button group" sx={{ml:2}}>
          <Button variant="contained" onClick={onConfirm1} color={btnColor1} size="small">
            {btnText1}
          </Button>
          <Button variant="contained" onClick={onConfirm2} color={btnColor2} size="small">
            {btnText2}
          </Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  );
};

export default WoopDialogBox;
