import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Paper,
  DialogTitle,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  styled,
  tableCellClasses,
  Card,
  CircularProgress,
  Box
} from '@mui/material';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(empId, empName, time) {
  return { empId, empName, time };
}

const rows = [createData('E0607', 'JOSEPH JAMES', 10), createData('E1009', 'ABISH ANTONY XAVIEM', 10)];

const dummyData = [
    { orderNumber: 1, materialDetails: 'Material A', materialSelected: true, quantity: 10 },
    { orderNumber: 2, materialDetails: 'Material B', materialSelected: false, quantity: 5 },
    { orderNumber: 3, materialDetails: 'Material C', materialSelected: true, quantity: 1  },
    { orderNumber: 4, materialDetails: 'Material D', materialSelected: false, quantity: 3 },
  ];

const MrdViewListDialogBox = ({ open, onClose, dataPop, onConfirm }) => {
  const [mrdDetails, setMrdDetails] = useState([]);

  const handleClose = () => {

    onClose();
  };

  const handleConfirm = () => {
    handleClose();
  };

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = () => {
    setLoading(true);
    // Fetch data from your API
    fetch('https://example.com/api/data')
      .then((response) => response.json())
      .then((data) => {
        setData(data); // Update the data state with the fetched data
        setLoading(false); // Set loading to false
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setLoading(false); // Set loading to false even in case of an error
        setData(dummyData);
      });
  };

  useEffect(() => {
    if (open ) {
      console.log(dataPop);
      fetchData();
      setMrdDetails(dataPop)  
    }
  }, [open]);


  const handleCheckboxChange = (index) => {
    const updatedData = [...data];
    updatedData[index].materialSelected = !updatedData[index].materialSelected;
    setData(updatedData);
  };

  const handleSave = () => {
    // Perform the API submission with the updated data here
    // For example, you can use the fetch function to send the data to the API

    // After a successful API call, you can close the dialog
    handleClose();
  };
  
  const dialogStyle = {
    borderRadius: '12px',
  };

  return (
    <Dialog open={open} maxWidth="md" fullWidth PaperProps={{ style: dialogStyle }}>
      <DialogTitle sx={{fontWeight:200}}><span style={{color:'GrayText', fontWeight: 700}}>{mrdDetails.DocumentNo}{" "}</span>: Material Requisition Document</DialogTitle>
      <DialogContent>
        <Card>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{width:2}}>Sl.No</StyledTableCell>
              <StyledTableCell align="left">Material Details</StyledTableCell>
              <StyledTableCell align="center" sx={{width:30}}>Quantity</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <StyledTableCell colSpan={3} align="center">
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <CircularProgress size={30} sx={{color: 'black'}}/>
                  </Box>                  
                  Loading...
                </StyledTableCell>
              </TableRow>
            ) : data.length === 0 ? (
              <TableRow>
                <StyledTableCell colSpan={3} align="center">No data found</StyledTableCell>
              </TableRow>
            ) : (
              data.map((row, index) => (
                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <StyledTableCell component="th" scope="row">{index + 1}</StyledTableCell>
                  <StyledTableCell align="left">{row.materialDetails}</StyledTableCell>
                  <StyledTableCell align="center">{row.quantity}</StyledTableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
        </Card>
      </DialogContent>
      <DialogActions sx={{pr:3, pb:2}}>
        <Button onClick={handleClose} variant="outlined" style={{ borderColor: 'black', color: 'black' }} size="small">
          Cancel
        </Button>   
      </DialogActions>
    </Dialog>
  );
};

export default MrdViewListDialogBox;
