import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import MachineryMasterPage from './pages/MachineryMasterPage';
import CreateMachineryPage from './pages/CreateMachineryPage';
import CreateUserPage from './pages/CreateUserPage';
import UserRoleMasterPage from './pages/UserRolesMasterPage';
import CreateUserRolePage from './pages/CreateUserRolePage';
import EmployeeMasterPage from './pages/EmployeeMasterPage';
import CreateEmplyoeePage from './pages/CreateEmployeePage';
import EmailAlertMasterPage from './pages/EmailAlertMasterPage';
import CreateEmailAlertPage from './pages/CreateEmailAlertPage';
import WorkOrderListPage from './pages/WorkOrderListPage';
import WoOperationPlanning from './pages/WoOperationPlanning';
import OperationActivitesPage from './pages/OperationActivitesPage';
import DpWoOperationPlanning from './pages/DpWoOperationPlanning';
import MaterialRequisitionPage from './pages/MaterialRequisitionPage';
import TimeSheetPage from './pages/TimeSheetPage';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard" />, index: true },
        { path: 'dashboard', element: <DashboardAppPage /> },
        { path: 'machineryMaster', element: <MachineryMasterPage /> },        
        { path: 'createMachinery', element: <CreateMachineryPage /> },      
        { path: 'userMaster', element: <UserPage /> },      
        { path: 'createUser', element: <CreateUserPage /> },     
        { path: 'userRoleMaster', element: <UserRoleMasterPage /> },      
        { path: 'createUserRole', element: <CreateUserRolePage /> },              
        { path: 'employeeMaster', element: <EmployeeMasterPage /> },        
        { path: 'createEmployee', element: <CreateEmplyoeePage /> },                        
        { path: 'emailAlertMaster', element: <EmailAlertMasterPage /> },        
        { path: 'createEmailAlert', element: <CreateEmailAlertPage /> },         
        { path: 'workOrderList', element: <WorkOrderListPage /> },          
        { path: 'woOperationPlanning', element: <WoOperationPlanning /> },        
        { path: 'operationActivites', element: <OperationActivitesPage /> },          
        { path: 'dpWoOperationPlanning', element: <DpWoOperationPlanning /> },            
        { path: 'materialRequisition', element: <MaterialRequisitionPage /> },            
        { path: 'timeSheets', element: <TimeSheetPage /> },  
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
