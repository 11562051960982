import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { sentenceCase } from 'change-case';
import dayjs from 'dayjs';
import {
  Card,
  Stack,
  Container,
  Typography,
  Breadcrumbs,
  CardContent,
  Grid,
  Button,
  TextField,
  Autocomplete,
  Table,
  TableHead,
  TableRow,
  TableContainer,
  TableBody,
  Paper,
  Divider,
  ButtonGroup,
  Switch
} from '@mui/material';

import toast from 'react-hot-toast';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import InputAdornment from '@mui/material/InputAdornment';

import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Label from '../components/label';
import Iconify from '../components/iconify';
import StatusLabel from '../components/status';

import WoopDialogBox from '../components/woop-dialog-box';

const subsidiaryList = [
  { label: 'BRI UAE', value: 'BRI UAE' },
  { label: 'PRS KSA', value: 'PRS KSA' },
  { label: 'BRI UAQ', value: 'BRI UAQ' },
];

const projectList = [
  { label: 'BRI UAE', value: 'BRI UAE' },
  { label: 'PRS KSA', value: 'PRS KSA' },
  { label: 'BRI UAQ', value: 'BRI UAQ' },
];

const workorderList = [
  { label: 'BRI UAE', value: 'BRI UAE' },
  { label: 'PRS KSA', value: 'PRS KSA' },
  { label: 'BRI UAQ', value: 'BRI UAQ' },
];

const row = {
  workOrderT: 'WO-12453',
  projectID: 'BRI UAE-J1-08-23',
  projectType: 'Projects',
  signType: 'Sign C 8.2',
  salesPerson: 'Nimesh',
  location: 'UAQ/Dubai',
  status: 'In Progress',
  plannedBy: 'Based on Login',
  lastModifiedBy: 'based on User',
};

function createData(operation, ESD, EED, ELH, progress, status) {
  return { operation, ESD, EED, ELH, progress, status };
}

const rows = [
  createData('CNC', '01-27-2023', '02-03-2023', 24, 'Completed', 'Active'),
  createData('Letter Molding', '02-03-2023', '02-05-2023', 37, 'Completed', 'Active'),
  createData('Fabrication', '02-09-2023', '02-12-2023', 24, 'In Progress', 'Active'),
  createData('Polishing', '02-15-2023', '02-17-2023', 67, 'In Progress', 'Active'),
  createData('Sanding', '02-18-2023', '02-19-2023', 49, 'Initiated', 'Active'),
  createData('Painting', '02-19-2023', '02-20-2023', 49, 'Initiated', 'Active'),
  createData('Acrylic', '02-21-2023', '02-23-2023', 49, 'Initiated', 'Active'),
  createData('Electrical', '02-24-2023', '02-24-2023', 49, 'Yet to Plan', 'Inactive'),
  createData('Screen Printing', '02-27-2023', '02-28-2023', 49, 'Yet to Plan', 'Inactive'),
  createData('Vinyl', '03-01-2023', '03-01-2023', 49, 'Yet to Plan', 'Inactive'),
  createData('Packaging', '03-02-2023', '03-02-2023', 49, 'Yet to Plan', 'Inactive'),
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableCell2 = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontWeight: 'bold',
  },
}));

export default function DpWoOperationPlanning() {

  const [tableData, setTableData] = useState(rows);
  const [estimatedLaborHours, setEstimatedLaborHours] = useState({});
  const handleStatusToggle = (row) => {
    // Clone the table data to avoid mutating the original state
    const updatedTableData = [...tableData];
  
    // Find the index of the row in the table data
    const rowIndex = updatedTableData.findIndex((dataRow) => dataRow.operation === row.operation);
  
    // Toggle the status for the specific row
    updatedTableData[rowIndex].status = row.status === 'Active' ? 'Inactive' : 'Active';
  
    // Update the table data state
    setTableData(updatedTableData);
  };


  const handleLaborHoursChange = (event, row) => {
    const newValue = event.target.value;
    // Update the estimatedLaborHours state with the new value for the specific row
    setEstimatedLaborHours((prevData) => ({
      ...prevData,
      [row.operation]: newValue,
    }));
  };

  const handleStartDateChange = (date, row) => {
    // Update the tableData state with the new date for the specific row
    const updatedTableData = tableData.map((rowData) => {
      if (rowData.operation === row.operation) {
        return { ...rowData, ESD: date };
      }
      return rowData;
    });
    setTableData(updatedTableData);
  };

  const handleEndDateChange = (date, row) => {
    // Update the tableData state with the new date for the specific row
    const updatedTableData = tableData.map((rowData) => {
      if (rowData.operation === row.operation) {
        return { ...rowData, EED: date };
      }
      return rowData;
    });
    setTableData(updatedTableData);
  };

  const handleSave = () => {
    // Map the table data to the desired format
    const formattedData = tableData.map((rowData) => ({
      operation: rowData.operation,
      ESD: dayjs(rowData.ESD).format('DD-MM-YYYY'), // Format Start Date as "day/month/year"
      EED: dayjs(rowData.EED).format('DD-MM-YYYY'), // Format End Date as "day/month/year"
      ELH: estimatedLaborHours[rowData.operation], // Estimated Labor Hours
      progress: rowData.progress,
      status: rowData.status,
    }));
  
    // Send the formattedData to your server or perform other actions
    console.log(formattedData);
  };

  useEffect(() => {
    const initialELH = {};
    rows.forEach((row) => {
      initialELH[row.operation] = row.ELH;
    });
    setEstimatedLaborHours(initialELH);
  }, []);


  const [holdDialogOpen, setHoldDialogOpen] = useState(false);

  const holdHandleDialogOpen = () => {
    setHoldDialogOpen(true);
  };

  const holdHandleDialogClose = () => {
    setHoldDialogOpen(false);
  };

  const holdWorkorderHandleDialogConfirm = () => {
    console.log('Work order Hold');
    toast.success('Workorder Successfully Placed on Hold!');
    holdHandleDialogClose();
  };

  const holdProjectHandleDialogConfirm = () => {
    console.log('Project Hold');    
    toast.success('Project Successfully Placed on Hold!');
    holdHandleDialogClose();
  };

  

const [cancelDialogOpen, setCancelDialogOpen] = useState(false);

const cancelHandleDialogOpen = () => {
  setCancelDialogOpen(true);
};

const cancelHandleDialogClose = () => {
  setCancelDialogOpen(false);
};

const cancelWorkorderHandleDialogConfirm = () => {
  console.log('Work order Cancel');
  toast.success('Workorder Successfully Placed on Cancel!');
  cancelHandleDialogClose();
};

const cancelProjectHandleDialogConfirm = () => {
  console.log('Project Cancel');    
  toast.success('Project Successfully Placed on Cancel!');
  cancelHandleDialogClose();
};

  const linknav = {
    lineHeight: '1.57143',
    fontSize: '0.875rem',
    fontFamily: 'Public Sans, sans-serif',
    fontWeight: '400',
    textDecoration: 'none',
    color: 'rgb(33, 43, 54)',
  };

  const actlinknav = {
    lineHeight: '1.57143',
    fontSize: '0.875rem',
    fontFamily: 'Public Sans, sans-serif',
    fontWeight: '400',
    textDecoration: 'none',
  };

  return (
    <>
      <Helmet>
        <title> WO Operation Planning | BRI Middleware </title>
      </Helmet>

      <Container maxWidth="100%">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <div>
            <Typography variant="h4" gutterBottom>
              WO Operation Planning
            </Typography>
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={
                <Iconify icon="ic:baseline-circle" width="6px" borderRadius="50%" color="rgb(145, 158, 171)" />
              }
            >
              <Link style={linknav} to="/dashboard">
                Dashboard
              </Link>
              <Link style={linknav} to="/machineryMaster">
                Work Order List
              </Link>
              <Typography style={actlinknav} color="text.secondary">
                WO Operation Planning
              </Typography>
            </Breadcrumbs>
          </div>
          <div>
            {/* <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
              New Machinery
            </Button> */}
          </div>
        </Stack>

        <Grid mb={5}>
          <Card>
            <Grid>
              <Typography sx={{pt:2, pb:2, pl:2}}>
                <Label color="info">WORK ORDER DETAILS</Label>
              </Typography>
                <Grid mt={1}>
                  <TableContainer component={Paper}>
                    <Table sx={{ maxWidth: '100%' }} size="small" aria-label="caption table">
                      <TableBody>
                        <TableRow>
                          <StyledTableCell2 align="left" width={150}>
                            Work Order:
                          </StyledTableCell2>
                          <TableCell align="left" colSpan={1}>
                            {row.workOrderT}
                          </TableCell>
                          <StyledTableCell2 align="left" width={150}>
                            Project Type:
                          </StyledTableCell2>
                          <TableCell align="left" colSpan={2}>
                            {row.projectType}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell2 align="left" width={150}>Sign Type:</StyledTableCell2>
                          <TableCell align="left">{row.signType}</TableCell>
                          <StyledTableCell2 align="left" width={150}>Sales Person:</StyledTableCell2>
                          <TableCell align="left">{row.salesPerson}</TableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell2 align="left">Location:</StyledTableCell2>
                          <TableCell align="left">{row.location}</TableCell>
                          <StyledTableCell2 align="left">Status:</StyledTableCell2>
                          <TableCell align="left">
                            <StatusLabel status={row.status} />                            
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell2 align="left">Planned By:</StyledTableCell2>
                          <TableCell align="left">{row.plannedBy}</TableCell>
                          <StyledTableCell2 align="left">Last Modified By:</StyledTableCell2>
                          <TableCell align="left">{row.lastModifiedBy}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Grid container justifyContent="flex-end" alignItems="flex-end" mt={2} gap={2} pr={2} pb={2}>
                    <Grid item>
                      <Button variant="contained" color="secondary" size="small" onClick={handleSave}>
                      Save 
                      </Button>
                    </Grid>
                    <Grid item>
                      <ButtonGroup>
                      <Button variant="contained" color="warning" size="small" onClick={holdHandleDialogOpen}>
                      Hold
                      </Button>
                      <Button variant="contained" color="error" size="small" onClick={cancelHandleDialogOpen}>
                      Cancel
                      </Button>
                      </ButtonGroup>
                    </Grid>
                  </Grid>
                </Grid>
            </Grid>
          </Card>
        </Grid>

        <Grid>
          <Card>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell style={{minWidth:200}}>Operation</StyledTableCell>
                    <StyledTableCell style={{maxWidth:112}} align="center">Estimated Start Date</StyledTableCell>
                    <StyledTableCell style={{maxWidth:112}} align="center">Estimated End Date</StyledTableCell>
                    <StyledTableCell style={{maxWidth:135}} align="center">Estimated Laber Hours</StyledTableCell>
                    <StyledTableCell style={{maxWidth:114}} align="center">Progress</StyledTableCell>
                    <StyledTableCell style={{maxWidth:114}} align="center">Status</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.operation} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell component="th" scope="row">
                        {row.operation}
                      </TableCell>
                      <TableCell align="center">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                  slotProps={{ textField: { size: 'small' } }}
                     defaultValue={dayjs(row.ESD)}
                    onChange={(dates) => handleStartDateChange(dates, row)}
                    format="DD-MM-YYYY"
                  />
                </LocalizationProvider>
              </TableCell>
              <TableCell align="center">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    slotProps={{ textField: { size: 'small' } }}
                    defaultValue={dayjs(row.EED)}
                    onChange={(dates) => handleEndDateChange(dates, row)}
                    format="DD-MM-YYYY"
                  />
                </LocalizationProvider>
              </TableCell>
              <TableCell align="center" style={{maxWidth:135}}>
                <TextField
                  size='small'
                  type="number"
                  value={estimatedLaborHours[row.operation] || ''}
      onChange={(e) => handleLaborHoursChange(e, row)}
      InputProps={{
        endAdornment: <InputAdornment position="end">hours</InputAdornment>,
      }}
                />
              </TableCell>
                      <TableCell align="center"> <StatusLabel status={row.progress} /> </TableCell>                      
                      <TableCell align="center">

                      <Switch
                        checked={row.status === 'Active'}
                        onChange={() => handleStatusToggle(row)}
                        color="primary"
                      />
                        
                        <Label color={row.status === 'Active' ? 'success' :'error'}>{sentenceCase(row.status)}</Label>
                      
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Grid>
      </Container>

      <WoopDialogBox 
        open={holdDialogOpen}
        onClose={holdHandleDialogClose}
        onConfirm1={holdWorkorderHandleDialogConfirm}
        onConfirm2={holdProjectHandleDialogConfirm}
        btnColor1={'warning'}
        btnColor2={'error'}
        dContent={"Please confirm which action you want to place on hold?"}
        dTitle={"Confirm Hold Action"}
        btnText1={'workorder'}
        btnText2={'Project'}
      />

      <WoopDialogBox 
        open={cancelDialogOpen}
        onClose={cancelHandleDialogClose}
        onConfirm1={cancelWorkorderHandleDialogConfirm}
        onConfirm2={cancelProjectHandleDialogConfirm}
        btnColor1={'warning'}
        btnColor2={'error'}
        dContent={"Please confirm which action you want to place on cancel?"}
        dTitle={"Confirm Cancel Action"}
        btnText1={'workorder'}
        btnText2={'Project'}
      />
    </>
  );
}
